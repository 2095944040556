import clsx from "clsx";
import api from "../../services/api";
import TicketInfo from "../TicketInfo";
import { toast } from "react-toastify";
import TicketHeader from "../TicketHeader";
import MessagesList from "../MessagesList";
import ContactDrawer from "../ContactDrawer";
import toastError from "../../errors/toastError";
import { TagsContainer } from "../TagsContainer";
import MessageInput from "../MessageInputCustom/";
import { Paper, makeStyles } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { socketConnection } from "../../services/socket";
import { validate as uuidValidate, v4 as uuidv4 } from "uuid";
import React, { useState, useEffect, useContext } from "react";
import TicketActionButtons from "../TicketActionButtonsCustom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100%",
        position: "relative",
        overflow: "hidden"
    },

    mainWrapper: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "0",
        marginRight: -drawerWidth,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    }
}));

const Ticket = () => {
    const classes = useStyles();
    const history = useHistory();
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState({});
    const { user } = useContext(AuthContext);
    const [contact, setContact] = useState({});
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        setLoading(true);

        (() => {
            const isntAValidTicketId = !uuidValidate(ticketId) || ticket == undefined;

            if (isntAValidTicketId) {
                toast.warning("Ticket não Encontrado. Voltando para os Tickets em Atendimento.");
                history.push("/tickets");
                return;
            }
        })();

        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get("/tickets/u/" + ticketId);
                    const { queueId, status } = data;
                    const { queues, profile } = user;

                    try {
                        // obtendo o componente que de notificação de mensagens não lidas por ticket
                        let elementExists = document.querySelector("#unreadMessagesTicket .MuiBadge-badge");
                        // verifica se esse componente existe
                        if (elementExists != null && elementExists != undefined && elementExists != "undefined") {
                            if (
                                data?.unreadMessages > 0 &&
                                data?.status === "open" &&
                                data?.userId === user?.id &&
                                parseFloat(localStorage.getItem("unreadMessagesTicket")) > 0
                            ) {
                                // caso ele exista, obtemos o valor de mensagens não lidas por ticket salvo no localStorage
                                let unreadMessagesTicket = parseFloat(localStorage.getItem("unreadMessagesTicket"));
                                // reduzindo a quantidade de mensagens não lidas
                                let reducingUnreadMessagesTicket =
                                    unreadMessagesTicket - parseFloat(data?.unreadMessages) > 0
                                        ? unreadMessagesTicket - parseFloat(data?.unreadMessages)
                                        : 0;

                                document.querySelector("#unreadMessagesTicket .MuiBadge-badge").textContent = reducingUnreadMessagesTicket;

                                localStorage.setItem("unreadMessagesTicket", reducingUnreadMessagesTicket);
                            }
                        }
                    } catch (error) {
                        // caso algum error ocorra, exibi o mesmo
                        console.log("Error ao tentar reduzir a quantidade de não mensagens lidas por ticket, funcao fetchTicket: ", error);
                    }

                    const queueAllowed = queues.find((q) => q.id === queueId);

                    if (!queueAllowed && !["closed", "campaign"].includes(status) && profile !== "admin") {
                        toast.error("Acesso não permitido");
                        history.push("/tickets");
                        return;
                    }

                    if (user.profile === "user" && data.status === "pending") {
                        toast.warning("Aviso você não tem permissão para acessar esse ticket!");
                        history.push("/tickets");
                        return;
                    }

                    setContact(data.contact);
                    setTicket(data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchTicket();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, user, history]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

        socket.on(`company-${companyId}-ticket`, (data) => {
            if (data.action === "update") {
                setTicket(data.ticket);
            }

            if (data.action === "delete") {
                toast.success("Ticket deletado com sucesso!");
                history.push("/tickets");
            }
        });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === "update") {
                setContact((prevState) => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact };
                    }
                    return prevState;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [ticketId, ticket, history]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const renderTicketInfo = () => {
        if (ticket.user !== undefined) {
            return <TicketInfo contact={contact} ticket={ticket} onClick={handleDrawerOpen} />;
        }
    };

    const renderMessagesList = () => {
        return (
            <>
                <MessagesList ticket={ticket} ticketId={ticket.id} isGroup={ticket.isGroup}></MessagesList>
                {ticket.status !== "closed" && <MessageInput ticketId={ticket.id} ticketStatus={ticket.status} />}
            </>
        );
    };

    return (
        <div className={classes.root} id="drawer-container">
            <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen
                })}>
                <TicketHeader loading={loading}>
                    {renderTicketInfo()}
                    <TicketActionButtons ticket={ticket} />
                </TicketHeader>
                <Paper>
                    <TagsContainer ticket={ticket} />
                </Paper>
                <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
            </Paper>
            <ContactDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} contact={contact} loading={loading} ticket={ticket} />
        </div>
    );
};

export default Ticket;
