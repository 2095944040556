import React, { useState, useEffect, useReducer, useContext, useRef, useCallback } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";

const useStyles = makeStyles((theme) => ({
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },

    ticketsList: {
        flex: 1,
        maxHeight: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTop: "2px solid rgba(0, 0, 0, 0.12)"
    },

    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px"
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4"
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px"
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach((ticket) => {
            const ticketIndex = state.findIndex((t) => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                // if (ticket.unreadMessages > 0) {
                //     state.unshift(state.splice(ticketIndex, 1)[0]);
                // }
            } else {
                state.push(ticket);
            }
        });

        // if (state.length > 0 && action.type === "LOAD_TICKETS" && action.payload.length >= 1) {
        //     return newTickets;
        // } else if (action.type === "LOAD_TICKETS" && action.payload.length === 0) {
        //     return [];
        // } else {
        //     return [...state];
        // }

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;
        const searching = action.searching === true;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            // só adiciona um novo item na lista se não estiver pesquisando
            if (searching === false) {
                state.push(ticket);
            }
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;
        const searching = action.searching === true;

        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            // state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            // só adiciona um novo item na lista se não estiver pesquisando
            if (searching === false) {
                state.push(ticket);
            }
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const performUpdate = (state, action) => {
    if (state === false && action.type === "UPDATE_TICKET_CONTACT") {
        return true;
    } else if (state === true && action.type === "UPDATE_TICKET_CONTACT") {
        return false;
    }
    if (state === false && action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        return true;
    } else if (state === true && action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        return false;
    }
    if (state === false && action.type === "DELETE_TICKET") {
        return true;
    } else if (state === true && action.type === "DELETE_TICKET") {
        return false;
    }
};

const TicketsListCustom = ({
    //
    status,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style
}) => {
    const classes = useStyles();
    const [pageNumber, setPageNumber] = useState(1);
    const [update, setUpdate] = useState(0);
    const [updateNow, doingUpdate] = useReducer(performUpdate, false);
    const [ticketsList, dispatch] = useReducer(reducer, []);
    const { user } = useContext(AuthContext);
    const { profile, queues } = user;

    const searchParamRef = useRef("");

    const shouldUpdateTicket = useCallback(
        (ticket) => {
            // valida se os usuários estão undefined
            const newUsers = [...(users ?? [])];

            return (
                // se não tiver usuário ou se for do usuário atual, ou quando o showAll tá ativo (admin pode ver todos)
                (!ticket.userId || ticket.userId === user?.id || showAll) &&
                // se tiver usuarios filtrados, só pega os usuários filtrados
                // colocamos o undefined, por que a página de resolvidos não tem a lista de usuários
                (newUsers?.some((id) => id === ticket.userId) || newUsers === undefined || newUsers?.length === 0) &&
                // se não tiver fila, e se tiver nas filas selecionadas
                (!ticket.queueId || selectedQueueIds.some((id) => id === ticket.queueId))
            );
        },
        [selectedQueueIds, showAll, user.id, users]
    );

    const { tickets, hasMore, loading } = useTickets({
        pageNumber,
        searchParam,
        status,
        updateNow: updateNow,
        showAll,
        tags: JSON.stringify(tags),
        users: JSON.stringify(users),
        queueIds: JSON.stringify(selectedQueueIds)
    });

    useEffect(() => {
        searchParamRef.current = searchParam;
    }, [searchParam]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [dispatch, status, searchParam, showAll, tags, users, selectedQueueIds]);

    useEffect(() => {
        dispatch({ type: "LOAD_TICKETS", payload: tickets.filter((ticket) => shouldUpdateTicket(ticket)) });
    }, [tickets, status, queues, profile, shouldUpdateTicket]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        const notBelongsToUserQueues = (ticket) => ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

        socket.on("connect", () => {
            if (status) {
                socket.emit("joinTickets", status);
            } else {
                socket.emit("joinNotification");
            }
        });

        socket.on(`company-${companyId}-ticket`, (data) => {
            if (data.action === "updateUnread") {
                dispatch({
                    type: "RESET_UNREAD",
                    payload: data.ticketId
                });
            }

            if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET",
                    searching: searchParamRef.current !== "",
                    payload: data.ticket
                });
            }

            if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
                dispatch({ type: "DELETE_TICKET", payload: data.ticket?.id });
                doingUpdate({
                    type: "DELETE_TICKET",
                    payload: true
                });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_TICKET", payload: data?.ticketId });
                doingUpdate({
                    type: "DELETE_TICKET",
                    payload: true
                });
            }

            if (data.action === "removeFromList") {
                dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
                doingUpdate({
                    type: "DELETE_TICKET",
                    payload: true
                });
            }
        });

        socket.on(`company-${companyId}-appMessage`, (data) => {
            const queueIds = queues.map((q) => q.id);
            if (profile === "user" && (queueIds.indexOf(data.ticket.queue?.id) === -1 || data.ticket.queue === null)) {
                return;
            }

            if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
                dispatch({
                    type: "UPDATE_TICKET_UNREAD_MESSAGES",
                    searching: searchParamRef.current !== "",
                    payload: data.ticket
                });
                doingUpdate({
                    type: "UPDATE_TICKET_UNREAD_MESSAGES",
                    payload: true
                });
            }
        });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === "update") {
                dispatch({
                    type: "UPDATE_TICKET_CONTACT",
                    payload: data.contact
                });
                doingUpdate({
                    type: "UPDATE_TICKET_CONTACT",
                    payload: true
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues, shouldUpdateTicket]);

    useEffect(() => {
        if (typeof updateCount === "function") {
            updateCount(ticketsList.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketsList]);

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const loadingMessageUnreadTicket = (() => {
        let updateCountMessageUnread = 0;

        return (ticket) => {
            try {
                if (ticket?.unreadMessages > 0 && ticket.status === "open" && ticket.user?.id === user?.id) {
                    updateCountMessageUnread += parseInt(ticket?.unreadMessages);

                    let elementExists = document.querySelector("#unreadMessagesTicket .MuiBadge-badge");
                    if (elementExists != null && elementExists != undefined && elementExists != "undefined") {
                        let unreadMessagesTicket = parseFloat(localStorage.getItem("unreadMessagesTicket"));

                        document.querySelector("#unreadMessagesTicket .MuiBadge-badge").textContent =
                            unreadMessagesTicket > updateCountMessageUnread ? unreadMessagesTicket : updateCountMessageUnread;

                        localStorage.setItem(
                            "unreadMessagesTicket",
                            unreadMessagesTicket > updateCountMessageUnread ? unreadMessagesTicket : updateCountMessageUnread
                        );
                    }
                }
            } catch (error) {
                console.log("Error ao tentar aumentar a quantidade de não mensagens lidas por ticket, funcao loadingMessageUnreadTicket: ", error);
            }
        };
    })();

    return (
        <Paper className={classes.ticketsListWrapper} style={style}>
            <Paper square name="closed" elevation={0} className={classes.ticketsList} onScroll={handleScroll}>
                <List style={{ paddingTop: 0 }}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>{i18n.t("ticketsList.noTicketsTitle")}</span>
                            <p className={classes.noTicketsText}>{i18n.t("ticketsList.noTicketsMessage")}</p>
                        </div>
                    ) : (
                        <>
                            {ticketsList.map((ticket) => {
                                loadingMessageUnreadTicket(ticket); // Executa a função sem retorno
                                return <TicketListItem ticket={ticket} setUpdate={setUpdate} key={ticket.id} />;
                            })}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Paper>
    );
};

export default TicketsListCustom;
