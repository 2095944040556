import React from "react";
import Badge from "@material-ui/core/Badge";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    badgeStyle: {
        color: "white",
        backgroundColor: green[500],
        right: 55,
        top: 15
    }
}));

const UnreadTicketMessages = () => {
    const classes = useStyles();
    return (
        <Badge
            Id={"unreadMessagesTicket"}
            badgeContent={localStorage.getItem("unreadMessagesTicket")} // exibi o valor de mensagens não lidas, armazenado no localStorage
            classes={{
                badge: classes.badgeStyle
            }}
        />
    );
};

export default UnreadTicketMessages;
